import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { StaticImage } from 'gatsby-plugin-image';
import loadable from '@loadable/component';

import HeaderComponent from '@components/molecules/header';
import MainTitleCard from '@components/molecules/main-title-card';
import Article from '@components/molecules/article';
import Seo from '@components/molecules/seo';
import Divider from '@components/atoms/divider';
import IconCardList from '@components/organisms/icon-card-list';
import { secondList } from '@data/industries/roofing.js';
import Title from '@components/molecules/title';
import TextButton from '@components/atoms/text-button';
import { apiUrl } from '@helpers';
import { container } from '@styles/main.module.scss';

import {
  productContainer,
  behindMask,
  scheduler,
  schedulerItem,
} from './time-tracking.module.scss';

const Modal = loadable(() => import('@components/molecules/modal'));
const FooterComponent = loadable(() => import('@components/molecules/footer'));
const SubscribeBanner = loadable(() => import('@components/molecules/subscribe-banner'));

const TeamManagement = () => {
  const Intl = useIntl();

  const [showDialog, setShowDialog] = useState(false);
  const openModal = () => setShowDialog(true);
  const closeModal = () => setShowDialog(false);
  const [values, setValues] = useState(null);

  const toggleDeleteInvite = (data) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: data.email }),
    };
    fetch(`${apiUrl}/delete-invite`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        closeModal();
        setValues(data);
        setTimeout(() => openModal(), 2000);
      });
  };

  const formSuccessState = (val) => {
    if (val?.action !== 'delete') {
      closeModal();
      setValues(val);
      setTimeout(() => {
        openModal();
      }, 500);
    } else {
      toggleDeleteInvite(val);
    }
  };

  const clockInOutTitle = (
    <>
      Clock in & out <br />
      with a single tap
    </>
  );

  return (
    <div className={`${container} ${productContainer}`}>
      <Modal
        close={closeModal}
        showDialog={showDialog}
        hasValues={values}
        onDelete={toggleDeleteInvite}
        setFormValues={(formValues) => formSuccessState(formValues)}
      />
      <Seo
        title={Intl.formatMessage({ id: 'pages.productOverview.timeTrackingMetaTitle' })}
        description={Intl.formatMessage({
          id: 'pages.productOverview.timeTrackingMetaDescription',
        })}
      />
      <HeaderComponent headerStyle="pricingHeader" />
      <span className={behindMask} />
      <MainTitleCard
        maxParagraphWidth={800}
        title={Intl.formatMessage({ id: 'pages.productOverview.timeTrackingBanner' })}
        subtitle={Intl.formatMessage({ id: 'pages.productOverview.timeTrackingBannerDesc' })}
      />
      <Divider className="style3" />
      <Article
        title={clockInOutTitle}
        description="Rosteroo’s intuitive mobile time clock makes it easy to track work hours from anywhere. Timely reminders help you never miss tracking hours for your shift again."
        image="add-notes"
        maxWidth={521}
        imagePadding="6rem 0"
        imageWidth={521}
        imageHeight={408}
      />
      <Divider className="style2" />
      <Title
        title="Detailed, accurate timesheet reports that work for you"
        maxWidth={920}
        maxDescriptionWidth={1000}
        description="No more cranking out spreadsheets by hand. Save hours of your time with Rosteroo’s automatically generated timesheets. Easily compare scheduled hours to hours tracked, every day."
      />
      <Divider className="style1" />
      <div className={`${scheduler}`}>
        <div className={`${schedulerItem}`}>
          <StaticImage
            src="../../../images/Detailed, accurate timesheet reports that work for you@2x.png"
            width={1260}
            height={906}
            alt="Find out what any employee is up to in seconds"
            quality={98}
          />
        </div>
      </div>
      <Divider className="style2" />
      <IconCardList cardList={secondList} style="twoCards" />
      <Divider className="style1" />
      <TextButton
        h1=""
        h2=""
        btnText={Intl.formatMessage({ id: 'pages.miscellaneous.startFreeTrial' })}
        onBtnClick={() => openModal()}
      />
      <Divider className="style3" />
      <Article
        title="Set up pay rates to save time on payroll"
        description="Set up multiple pay rates based on a staff member’s job position or for different time periods. Get detailed reports based on your pay period and never second-guess your data when processing payroll."
        image="give-managers"
        maxWidth={304}
        imagePadding="6rem 0"
        imageWidth={304}
        imageHeight={466}
      />
      <Article
        isSwapped
        title="Get attendance updates in real-time"
        description="Make sure you’re always covered. Quickly find out who’s on the clock, who’s running late for their shift or who’s available to work and cover for any unexpected shifts."
        image="ensure-employees"
        maxWidth={340}
        imagePadding="6rem 0"
        imageWidth={340}
        imageHeight={444.14}
      />
      <Article
        title="Adjust time entries for accurate tracking"
        description="Ensure all your work hours accounted for. Manually add or edit time entries from any device. Add shift details and notes for your manager to review and approve."
        image="get-notification"
        maxWidth={328}
        imagePadding="6rem 0"
        imageWidth={328}
        imageHeight={433.03}
      />
      <Article
        isSwapped
        title="Guaranteed accuracy with time restrictions"
        description="Add time tracking restrictions that guarantee data credibility. Prevent your staff from starting unscheduled shifts, clocking in before a shift starts or entering time entries manually."
        image="record-pto"
        maxWidth={304}
        imagePadding="6rem 0"
        imageWidth={304}
        imageHeight={315}
      />
      <Divider className="style3" />
      <SubscribeBanner
        title={Intl.formatMessage({ id: 'pages.pricing.timeTrackingTitle' })}
        subtitle={Intl.formatMessage({ id: 'pages.pricing.subscribeBannerDesc' })}
        placeholder={Intl.formatMessage({ id: 'pages.miscellaneous.typeYourEmail' })}
        checkItemOne={Intl.formatMessage({ id: 'pages.miscellaneous.noCreditCard' })}
        checkItemTwo={Intl.formatMessage({ id: 'pages.miscellaneous.14DaysTrial' })}
        checkItemThree={Intl.formatMessage({ id: 'pages.miscellaneous.cancelAnytime' })}
      />
      <FooterComponent />
    </div>
  );
};

export default TeamManagement;
